<template>
  <div>
    <div class="investor_workbench_table_title_total flex_center_around_box">
      <div class="flex_box">
        <div class="investor_workbench_table_title_total_tit">申请投资总数(元):</div>
        <div class="workbench_table_title_total_button">{{dataListTotal.totalApplied | NumFormat}}</div>
        <div class="vertical_line"></div>
      </div>
      <div class="flex_box">
        <div class="investor_workbench_table_title_total_tit">申请投资公司总数(家):</div>
        <div class="workbench_table_title_total_button">{{dataListTotal.totalApplyCompanies}}</div>
        <div class="vertical_line"></div>
      </div>
      <div class="flex_box">
        <div class="investor_workbench_table_title_total_tit">总投资(元):</div>
        <div class="workbench_table_title_total_button">{{dataListTotal.totalInvestment | NumFormat}}</div>
        <div class="vertical_line"></div>
      </div>
      <div class="flex_box">
        <div class="investor_workbench_table_title_total_tit">平均投资回报率(%):</div>
        <div class="workbench_table_title_total_button">{{dataListTotal.avgReturnInvestment}}</div>
      </div>
    </div>
    <!-- <ul class="workbench_link_div flex_center">
            <li v-for="(item,index) in titData" :key="index" class="cur_point">
                <div @click="go(index+1)">
                    <div class="workbench_tit">{{item.tit}}</div>
                    <div class="workbench_icon"><img :src="item.img" /></div>
                    <div class="workbench_tol">{{item.tol}}</div>
                    <div class="workbench_num">{{item.num}}</div>
                </div>
                <div class="workbench_bottom" @click="onResearch(index+1)"><div class="iconfont icon-xiayiye-xianxing3-0"></div></div>
            </li>
        </ul> -->
    <div class="workbench_lunbo flex_center">
      <div :class="index==0?'white workbench_lunbo_prev cur_point':'blue workbench_lunbo_prev cur_point'" @click="direction('prev')" :style="{cursor:index==0?'not-allowed':'pointer'}">
        <img :src="index==0?prevImg:nextImg" :class="index==0?'':'deg180'" />
      </div>
      <div class="workbench_lunbo_con">
        <ul class="workbench_link_div flex_center_box" ref="lunbo">
          <li v-for="(item,index) in titData" :key="index" class="cur_point">
            <div @click="go(index+1)">
              <div class="workbench_tit">{{item.tit}}</div>
              <div class="workbench_icon"><img :src="item.img" /></div>
              <div class="workbench_tol">{{item.tol}}</div>
              <div class="workbench_num">{{item.num}}</div>
            </div>
            <div class="workbench_bottom" @click="onResearch(index+1)">
              <div class="iconfont icon-xiayiye-xianxing3-0"></div>
            </div>
          </li>
        </ul>
      </div>
      <div :class="index==(lunboIndex-5)?'white workbench_lunbo_next cur_point':'blue workbench_lunbo_next cur_point'" @click="direction('next')" :style="{cursor:index==3?'not-allowed':'pointer'}">
        <img :src="index==(lunboIndex-5)?prevImg:nextImg" :class="index==(lunboIndex-5)?'deg180':''">
      </div>
    </div>
    <div class="table_common_work" ref="performance1">
      <div class="table_common_work_tit flex_center_between_box">
        <div>我的公海</div>
        <div class="flex_center_end_box workbench_table_title_more" @click="go(1)">查看更多<div class="iconfont icon-xiayiye-xianxing3-0"></div>
        </div>
      </div>
      <div class="flex_center_between_box table_common_work_echart_div">
        <div class="table_common_work_echart table_common_work_echart7 echart_between">
          <div ref="echarts1" class="table_common_work_echart1_allwidth" v-show="this.echarts1Flag"></div>
          <div class="nodata nodataTop" v-show="!this.echarts1Flag">暂无数据，敬请期待…</div>
        </div>
        <div class="table_common_work_echart table_common_work_echart7 echart_between">
          <div ref="echarts2" class="table_common_work_echart1_allwidth" v-show="this.echarts2Flag"></div>
          <div class="nodata nodataTop" v-show="!this.echarts2Flag">暂无数据，敬请期待…</div>
        </div>
      </div>
      <div class="flex_center_between_box table_common_work_echart_div">
        <div class="table_common_work_echart table_common_work_echart7">
          <div ref="echarts3" class="table_common_work_echart1_allwidth" v-show="this.echarts3Flag"></div>
          <div class="nodata nodataTop" v-show="!this.echarts3Flag">暂无数据，敬请期待…</div>
        </div>
        <div class="table_common_work_echart table_common_work_echart7">
          <div ref="echarts4" class="table_common_work_echart1_allwidth" v-show="this.echarts4Flag"></div>
          <div class="nodata nodataTop" v-show="!this.echarts4Flag">暂无数据，敬请期待…</div>
        </div>
      </div>
      <div>
        <div class="workbench_table_title_total workbench_table_title_total_top flex_box">
          <div class="workbench_table_title_total_bottom_dashed">
            <div class="flex_box">
              <div class="workbench_table_title_total_tit">申请投资总金额(元)</div>
              <div class="workbench_table_title_total_button">{{dataListTotal.totalAmountApplied | NumFormat}}</div>
              <div class="vertical_line"></div>
            </div>
            <div class="flex_box">
              <div class="workbench_table_title_total_tit1">申请投资平均金额(元)</div>
              <div class="workbench_table_title_total_button">{{dataListTotal.avgAmountApplied | NumFormat}}</div>
              <div class="vertical_line"></div>
            </div>
            <div class="flex_box">
              <div class="workbench_table_title_total_tit1">申请投资平均利率(%)</div>
              <div class="workbench_table_title_total_button">{{dataListTotal.avgInterestRate}}</div>
              <div class="vertical_line"></div>
            </div>
          </div>
        </div>
        <div class="workbench_table_title_total workbench_table_title_total_bottom flex_box">
          <div class="workbench_table_title_total_bottom_dashed">
            <div class="flex_box">
              <div class="workbench_table_title_total_tit">申请投资平均账期(天)</div>
              <div class="workbench_table_title_total_button">{{dataListTotal.avgAccountPeriod}}</div>
              <div class="vertical_line"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table_common_work" ref="performance2">
      <div class="table_common_work_tit flex_center_between_box">
        <div>我的私海</div>
        <div class="flex_center_end_box workbench_table_title_more" @click="go(2)">查看更多<div class="iconfont icon-xiayiye-xianxing3-0"></div>
        </div>
      </div>
      <div class="flex_center_between_box table_common_work_echart_div">
        <div class="table_common_work_echart table_common_work_echart11 flex_center_between_box">
          <div ref="echarts5" class="table_common_work_echart1" v-show="this.echarts5Flag"></div>
          <div class="nodata" v-show="!this.echarts5Flag">暂无数据，敬请期待…</div>
          <div class="table_common_work_echart_details" v-show="this.echarts5Flag">
            <ul>
              <li class="flex_center_between_box">
                <div class="tit">邀约数量：</div>
                <div>{{dataListTotal.invitationsNum}}个</div>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">已签约数量：</div>
                <div>{{dataListTotal.signedQuantity}}个</div>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">未签约数量：</div>
                <div>{{dataListTotal.uncontractedQuantity}}个</div>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">已放款数量：</div>
                <div>{{dataListTotal.lentQuantity}}个</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="table_common_work" ref="performance3">
      <div class="table_common_work_tit flex_center_between_box">
        <div>我的风险池</div>
        <div class="flex_center_end_box workbench_table_title_more" @click="go(3)">查看更多<div class="iconfont icon-xiayiye-xianxing3-0"></div>
        </div>
      </div>
      <div class="flex_center_between_box table_common_work_echart_div">
        <div class="table_common_work_echart flex_center_between_box echart_between">
          <div ref="echarts6" class="table_common_work_echart1" v-show="this.echarts6Flag"></div>
          <div class="nodata" v-show="!this.echarts6Flag">暂无数据，敬请期待…</div>
          <div class="table_common_work_echart_details" v-show="this.echarts6Flag">
            <ul>
              <li class="flex_center_between_box">
                <div class="tit">投资单数：</div>
                <div>{{dataListTotal.investmentOrders}}</div>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">未逾期单数：</div>
                <div>{{dataListTotal.overdueNum}}</div>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">已逾期单数：</div>
                <div>{{dataListTotal.overdueOddNum}}</div>
              </li>
            </ul>
          </div>
        </div>
        <div class="table_common_work_echart flex_center_between_box echart_between">
          <div ref="echarts7" class="table_common_work_echart1" v-show="this.echarts7Flag"></div>
          <div class="nodata" v-show="!this.echarts7Flag">暂无数据，敬请期待…</div>
          <div class="table_common_work_echart_details" v-show="this.echarts7Flag">
            <ul>
              <li class="flex_center_between_box">
                <div class="tit">投资总金额：</div>
                <el-popover placement="top" trigger="hover" :content="filter(dataListTotal.totalInvestAmount) + '元'" popper-class="popper_class_account">
                  <div slot="reference" class="text_overflow">{{dataListTotal.totalInvestAmount | NumFormat}}元</div>
                </el-popover>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">未逾期金额：</div>
                <el-popover placement="top" trigger="hover" :content="filter(dataListTotal.overdueNotamount) + '元'" popper-class="popper_class_account">
                  <div slot="reference" class="text_overflow">{{dataListTotal.overdueNotamount | NumFormat}}元</div>
                </el-popover>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">逾期金额：</div>
                <el-popover placement="top" trigger="hover" :content="filter(dataListTotal.overdueAmount) + '元'" popper-class="popper_class_account">
                  <div slot="reference" class="text_overflow">{{dataListTotal.overdueAmount | NumFormat}}元</div>
                </el-popover>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="flex_center_between_box table_common_work_echart_div">
        <div class="table_common_work_echart table_common_work_echart7">
          <div ref="echarts8" class="table_common_work_echart1_allwidth" v-show="this.echarts8Flag"></div>
          <div class="nodata nodataTop" v-show="!this.echarts8Flag">暂无数据，敬请期待…</div>
        </div>
        <div class="table_common_work_echart table_common_work_echart7">
          <div ref="echarts9" class="table_common_work_echart1_allwidth" v-show="this.echarts9Flag"></div>
          <div class="nodata nodataTop" v-show="!this.echarts9Flag">暂无数据，敬请期待…</div>
        </div>
      </div>
      <div>
        <div class="workbench_table_title_total flex_box">
          <div class="workbench_table_title_total_bottom_dashed">
            <div class="flex_box">
              <div class="workbench_table_title_total_tit">最高逾期额(元)</div>
              <div class="workbench_table_title_total_button">{{dataListTotal.maxOverdueAmount | NumFormat}}</div>
              <div class="vertical_line"></div>
            </div>
            <div class="flex_box">
              <div class="workbench_table_title_total_tit1">最低逾期额(元)</div>
              <div class="workbench_table_title_total_button">{{dataListTotal.minOverdueAmount | NumFormat}}</div>
              <div class="vertical_line"></div>
            </div>
            <div class="flex_box">
              <div class="workbench_table_title_total_tit1">平均逾期额(元)</div>
              <div class="workbench_table_title_total_button">{{dataListTotal.avgOverdueAmount | NumFormat}}</div>
              <div class="vertical_line"></div>
            </div>
          </div>
        </div>
        <div class="workbench_table_title_total flex_box">
          <div class="workbench_table_title_total_bottom_dashed">
            <div class="flex_box">
              <div class="workbench_table_title_total_tit">最高逾期天数(天)</div>
              <div class="workbench_table_title_total_button">{{dataListTotal.maxOverdueDays}}</div>
              <div class="vertical_line"></div>
            </div>
            <div class="flex_box">
              <div class="workbench_table_title_total_tit1">最低逾期天数(天)</div>
              <div class="workbench_table_title_total_button">{{dataListTotal.minOverdueDays}}</div>
              <div class="vertical_line"></div>
            </div>
            <div class="flex_box">
              <div class="workbench_table_title_total_tit1">平均逾期天数(天)</div>
              <div class="workbench_table_title_total_button">{{dataListTotal.avgOverdueDays}}</div>
              <div class="vertical_line"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table_common_work" ref="performance4">
      <div class="table_common_work_tit flex_center_between_box">
        <div>我的投资</div>
        <div class="flex_center_end_box workbench_table_title_more" @click="go(4)">查看更多<div class="iconfont icon-xiayiye-xianxing3-0"></div>
        </div>
      </div>
      <div class="flex_center_between_box table_common_work_echart_div">
        <div class="table_common_work_echart flex_center_between_box echart_between">
          <div ref="echarts10" class="table_common_work_echart1" v-show="this.echarts10Flag"></div>
          <div class="nodata" v-show="!this.echarts10Flag">暂无数据，敬请期待…</div>
          <div class="table_common_work_echart_details" v-show="this.echarts10Flag">
            <ul>
              <li class="flex_center_between_box">
                <div class="tit">投资单数：</div>
                <div>{{dataListTotal.investmentOrders}}</div>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">已还款单数：</div>
                <div>{{dataListTotal.repaymentOrders}}</div>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">未还款单数：</div>
                <div>{{dataListTotal.outstandOrders}}</div>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">逾期单数：</div>
                <div>{{dataListTotal.overdueOrders}}</div>
              </li>
            </ul>
          </div>
        </div>
        <div class="table_common_work_echart flex_center_between_box echart_between">
          <div ref="echarts11" class="table_common_work_echart1" v-show="this.echarts11Flag"></div>
          <div class="nodata" v-show="!this.echarts11Flag">暂无数据，敬请期待…</div>
          <div class="table_common_work_echart_details" v-show="this.echarts11Flag">
            <ul>
              <li class="flex_center_between_box">
                <div class="tit">投资总金额：</div>
                <el-popover placement="top" trigger="hover" :content="filter(dataListTotal.totalInvestmentAmount) + '元'" popper-class="popper_class_account">
                  <div slot="reference" class="text_overflow">{{dataListTotal.totalInvestmentAmount | NumFormat}}元</div>
                </el-popover>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">已还款金额：</div>
                <el-popover placement="top" trigger="hover" :content="filter(dataListTotal.repaymentAmount) + '元'" popper-class="popper_class_account">
                  <div slot="reference" class="text_overflow">{{dataListTotal.repaymentAmount | NumFormat}}元</div>
                </el-popover>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">未还款金额：</div>
                <el-popover placement="top" trigger="hover" :content="filter(dataListTotal.outstandAmount) + '元'" popper-class="popper_class_account">
                  <div slot="reference" class="text_overflow">{{dataListTotal.outstandAmount | NumFormat}}元</div>
                </el-popover>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">逾期金额：</div>
                <el-popover placement="top" trigger="hover" :content="filter(dataListTotal.overdueOrderAmount) + '元'" popper-class="popper_class_account">
                  <div slot="reference" class="text_overflow">{{dataListTotal.overdueOrderAmount | NumFormat}}元</div>
                </el-popover>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="flex_center_between_box table_common_work_echart_div">
        <div class="table_common_work_echart table_common_work_echart7 echart_between">
          <div ref="echarts12" class="table_common_work_echart1_allwidth" v-show="this.echarts12Flag"></div>
          <div class="nodata nodataTop" v-show="!this.echarts12Flag">暂无数据，敬请期待…</div>
        </div>
        <div class="table_common_work_echart table_common_work_echart7 echart_between">
          <div ref="echarts13" class="table_common_work_echart1_allwidth" v-show="this.echarts13Flag"></div>
          <div class="nodata nodataTop" v-show="!this.echarts13Flag">暂无数据，敬请期待…</div>
        </div>
      </div>
      <div class="flex_center_between_box table_common_work_echart_div">
        <div class="table_common_work_echart table_common_work_echart7">
          <div ref="echarts14" class="table_common_work_echart1_allwidth" v-show="this.echarts14Flag"></div>
          <div class="nodata nodataTop" v-show="!this.echarts14Flag">暂无数据，敬请期待…</div>
        </div>
        <div class="table_common_work_echart table_common_work_echart7">
          <div ref="echarts15" class="table_common_work_echart1_allwidth" v-show="this.echarts15Flag"></div>
          <div class="nodata nodataTop" v-show="!this.echarts15Flag">暂无数据，敬请期待…</div>
        </div>
      </div>
      <div class="workbench_table_title_total_background">
        <div class="workbench_table_title_total flex_box">
          <div class="workbench_table_title_total_bottom_dashed">
            <div class="flex_box">
              <div class="workbench_table_title_total_tit">最高投资额(元)</div>
              <div class="workbench_table_title_total_button">{{dataListTotal.maxInvest | NumFormat}}</div>
              <div class="vertical_line"></div>
            </div>
            <div class="flex_box">
              <div class="workbench_table_title_total_tit1">最低投资额(元)</div>
              <div class="workbench_table_title_total_button">{{dataListTotal.minInvest | NumFormat}}</div>
              <div class="vertical_line"></div>
            </div>
            <div class="flex_box">
              <div class="workbench_table_title_total_tit1">平均投资额(元)</div>
              <div class="workbench_table_title_total_button">{{dataListTotal.avgInvest | NumFormat}}</div>
              <div class="vertical_line"></div>
            </div>
          </div>
        </div>
        <div class="workbench_table_title_total flex_box">
          <div class="workbench_table_title_total_bottom_dashed">
            <div class="flex_box">
              <div class="workbench_table_title_total_tit">最高投资利率(%)</div>
              <div class="workbench_table_title_total_button">{{dataListTotal.maxInvestRate}}</div>
              <div class="vertical_line"></div>
            </div>
            <div class="flex_box">
              <div class="workbench_table_title_total_tit1">最低投资利率(%)</div>
              <div class="workbench_table_title_total_button">{{dataListTotal.minInvestRate}}</div>
              <div class="vertical_line"></div>
            </div>
            <div class="flex_box">
              <div class="workbench_table_title_total_tit1">平均投资利率(%)</div>
              <div class="workbench_table_title_total_button">{{dataListTotal.avgInvestRate}}</div>
              <div class="vertical_line"></div>
            </div>
          </div>
        </div>
        <div class="workbench_table_title_total flex_box">
          <div class="workbench_table_title_total_bottom_dashed">
            <div class="flex_box">
              <div class="workbench_table_title_total_tit">最高回款天数(天)</div>
              <div class="workbench_table_title_total_button">{{dataListTotal.maxCollectionDays}}</div>
              <div class="vertical_line"></div>
            </div>
            <div class="flex_box">
              <div class="workbench_table_title_total_tit1">最低回款天数(天)</div>
              <div class="workbench_table_title_total_button">{{dataListTotal.minCollectionDays}}</div>
              <div class="vertical_line"></div>
            </div>
            <div class="flex_box">
              <div class="workbench_table_title_total_tit1">平均回款天数(天)</div>
              <div class="workbench_table_title_total_button">{{dataListTotal.avgCollectionDays}}</div>
              <div class="vertical_line"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table_common_work" ref="performance5">
      <div class="table_common_work_tit flex_center_between_box">
        <div>我的服务</div>
        <div class="flex_center_end_box workbench_table_title_more" @click="go(5)">查看更多<div class="iconfont icon-xiayiye-xianxing3-0"></div>
        </div>
      </div>
      <div class="flex_center_between_box table_common_work_echart_div">
        <div class="table_common_work_echart table_common_work_echart11 flex_center_between_box">
          <div ref="echarts16" class="table_common_work_echart1" v-show="this.echarts16Flag"></div>
          <div class="nodata" v-show="!this.echarts16Flag">暂无数据，敬请期待…</div>
          <div class="table_common_work_echart_details table_common_work_echart_details1" v-show="this.echarts16Flag">
            <ul>
              <li class="flex_center_between_box">
                <div class="tit">服务总数：</div>
                <div>{{dataListTotal.totalService}}个</div>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">已处理服务数：</div>
                <div>{{dataListTotal.processedService}}个</div>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">未处理服务数：</div>
                <div>{{dataListTotal.untreatedService}}个</div>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">即将过期服务数：</div>
                <div>{{dataListTotal.willExpireService}}个</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <template>
      <el-backtop :visibility-height='220' :bottom='200' :right='150' class="backtop_div">
        <div class="iconfont icon-huidaodingbu"></div>
      </el-backtop>
    </template>
  </div>
</template>
<script>
import { mixins1 } from "@/mixins/index";
import { scrollAnimation } from '@/assets/js/top'
import {
  capital, pubSeaApplyInvestAmount, pubSeaApplyInvestRate, pubSeaApplyInvestTerm, pubSeaApplyTrend, riskPoolOverdueDistribution, riskPoolOverdueInvest
  , investAmount, investRate, investCollectionDays, investTrend
} from '@/api/investor.js'
import { throws } from "assert";
export default {
  mixins: [mixins1],
  data() {
    return {
      index: 0,
      menuList: [
        {
          name: '我的工作台',
          path: '/system/investor/workbench'
        },
        {
          name: '我的公海',
          path: '/system/investor/publicResource'
        },
        {
          name: '我的私海',
          path: '/system/investor/privateResources'
        },
        {
          name: '我的风险池',
          path: '/system/investor/riskPool'
        },
        {
          name: '我的投资',
          path: '/system/investor/postLoanManagement'
        },
        {
          name: '我的服务',
          path: '/system/investor/myService'
        },
        {
          name: '功能定义',
          path: '/system/investor/functionDefinition'
        },
      ],
      titData: [
        {
          tit: '我的公海',
          img: require('@/assets/img/system/investor/whome1.png'),
          tol: '申请投资笔数(笔)',
          num: '0',
        },
        {
          tit: '我的私海',
          img: require('@/assets/img/system/investor/whome2.png'),
          tol: '邀约总数(个)',
          num: '0',
        },
        {
          tit: '我的风险池',
          img: require('@/assets/img/system/investor/whome3.png'),
          tol: '投资总额(元)',
          num: '0',
        },
        {
          tit: '我的投资',
          img: require('@/assets/img/system/investor/whome4.png'),
          tol: '投资总额(元)',
          num: '0',
        },
        {
          tit: '我的服务',
          img: require('@/assets/img/system/supplier/whome6.png'),
          tol: '服务总数(个)',
          num: '0',
        },
        {
          tit: '功能定义',
          img: require('@/assets/img/system/supplier/whome8.png'),
          tol: '自定义(%)',
          num: '-',
        }
      ],
      prevImg: require('@/assets/img/prev.png'),
      nextImg: require('@/assets/img/next.png'),
      fourChart: null,
      DateList1: [],
      DateList2: [],
      DateList3: [],
      DateList4: [],
      DateList5: [{
        value: 0,
        name: '未签约'
      }, {
        value: 0,
        name: '已签约'
      }],
      DateList6: [{
        value: 0,
        name: '已逾期'
      }, {
        value: 0,
        name: '未逾期'
      }],
      DateList7: [{
        value: 0,
        name: '逾期金额'
      }, {
        value: 0,
        name: '未逾期金额'
      }],
      DateList8: [],
      DateList9: [],
      DateList10: [{
        value: 0,
        name: '未还款单数'
      }, {
        value: 0,
        name: '已还款单数'
      }],
      DateList11: [{
        value: 0,
        name: '未还款金额'
      }, {
        value: 0,
        name: '已还款金额'
      }],
      DateList12: [],
      DateList13: [],
      DateList14: [],
      DateList15: [],
      DateList16: [{
        value: 0,
        name: '未处理'
      }, {
        value: 0,
        name: '已处理'
      }],
      yLineHeight: 18,
      dataListTotal: {},
      maxData: [],
      echarts1Flag: true,
      echarts2Flag: true,
      echarts3Flag: true,
      echarts4Flag: true,
      echarts5Flag: true,
      echarts6Flag: true,
      echarts7Flag: true,
      echarts8Flag: true,
      echarts9Flag: true,
      echarts10Flag: true,
      echarts11Flag: true,
      echarts12Flag: true,
      echarts13Flag: true,
      echarts14Flag: true,
      echarts15Flag: true,
      echarts16Flag: true,
    }
  },
  created() {
    this.tableWidth()
    this.resizeFn()
    this.capitalWork()
  },
  methods: {
    async capitalWork() {
      let res = await capital()
      this.dataListTotal = res.data
      this.DateList5[0].value = res.data.uncontractedQuantity
      this.DateList5[1].value = res.data.signedQuantity
      this.DateList6[0].value = res.data.overdueOddNum
      this.DateList6[1].value = res.data.overdueNum
      this.DateList7[0].value = res.data.overdueAmount
      this.DateList7[1].value = res.data.overdueNotamount
      this.DateList10[0].value = res.data.outstandOrders
      this.DateList10[1].value = res.data.repaymentOrders
      this.DateList11[0].value = res.data.outstandAmount
      this.DateList11[1].value = res.data.repaymentAmount
      this.DateList16[0].value = res.data.untreatedService
      this.DateList16[1].value = res.data.processedService
      this.titData[0].num = res.data.applicationInvestmentNum
      this.titData[1].num = res.data.totalInvitationNum
      this.titData[2].num = this.filter(res.data.totalInvestRiskpool)
      this.titData[3].num = this.filter(res.data.totalInvestInvestment)
      this.titData[4].num = res.data.totalService
      this.titData[5].num = res.data.functionPercent
      this.$nextTick(() => {
        this.echarts1(this.pieFontSize, this.pieFontSize1, this.yLineHeight, this.marginHeight, this.gridbottom)
        this.echarts2(this.pieFontSize, this.pieFontSize1, this.yLineHeight, this.marginHeight, this.gridbottom)
        this.echarts3(this.pieFontSize, this.pieFontSize1, this.yLineHeight, this.marginHeight, this.gridbottom)
        this.echarts4(this.pieFontSize, this.pieFontSize1, this.yLineHeightT)
        this.echarts5(this.pieFontSize, this.pieFontSize1)
        this.echarts6(this.pieFontSize, this.pieFontSize1)
        this.echarts7(this.pieFontSize, this.pieFontSize1)
        this.echarts8(this.pieFontSize, this.pieFontSize1, this.yLineHeight, this.marginHeight, this.gridbottom)
        this.echarts9(this.pieFontSize, this.pieFontSize1, this.yLineHeight, this.marginHeight, this.gridbottom, this.fontWidthbar, this.offset)
        this.echarts10(this.pieFontSize, this.pieFontSize1)
        this.echarts11(this.pieFontSize, this.pieFontSize1)
        this.echarts12(this.pieFontSize, this.pieFontSize1, this.yLineHeight, this.marginHeight, this.gridbottom)
        this.echarts13(this.pieFontSize, this.pieFontSize1, this.yLineHeight, this.marginHeight, this.gridbottom)
        this.echarts14(this.pieFontSize, this.pieFontSize1, this.yLineHeight, this.marginHeight, this.gridbottom)
        this.echarts15(this.pieFontSize, this.pieFontSize1, this.yLineHeightT)
        this.echarts16(this.pieFontSize, this.pieFontSize1)
      })
    },
    onResearch(index) {
      console.log(index)
      this.$nextTick(function () {
        if (index === 1) {
          const top = this.$refs.performance1.offsetTop
          const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
          scrollAnimation(scrollTop, top)
        } else if (index === 2) {
          const top = this.$refs.performance2.offsetTop
          const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
          scrollAnimation(scrollTop, top)
        } else if (index === 3) {
          const top = this.$refs.performance3.offsetTop
          const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
          scrollAnimation(scrollTop, top)
        } else if (index === 4) {
          const top = this.$refs.performance4.offsetTop
          const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
          scrollAnimation(scrollTop, top)
        } else if (index === 6) {
          const top = this.$refs.performance6.offsetTop
          const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
          scrollAnimation(scrollTop, top)
        } else if (index === 5) {
          const top = this.$refs.performance5.offsetTop
          const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
          scrollAnimation(scrollTop, top)
        }
      })
    },
    go(index) {
      this.$router.push(this.menuList[index].path)
    },
    async echarts1(pieFontSize, pieFontSize1, yLineHeight, marginHeight, gridbottom) {
      let res = await pubSeaApplyInvestAmount()
      console.log(res)
      if (res.data.length == 0) {
        this.echarts1Flag = false
      }
      res.data.forEach((item, index) => {
        this.DateList1[index] = []
        this.DateList1[index][0] = item.name
        this.DateList1[index][1] = item.amount
      })
      this.fourChart = this.$echarts.init(this.$refs.echarts1)
      this.echartsbar(this.DateList1, '申请投资金额', '元', pieFontSize, pieFontSize1, yLineHeight, marginHeight, gridbottom)
    },
    async echarts2(pieFontSize, pieFontSize1, yLineHeight, marginHeight, gridbottom) {
      let res = await pubSeaApplyInvestRate()
      if (res.data.length == 0) {
        this.echarts2Flag = false
      }
      console.log(res)
      res.data.forEach((item, index) => {
        this.DateList2[index] = []
        this.DateList2[index][0] = item.name
        this.DateList2[index][1] = item.rate
      })
      this.fourChart = this.$echarts.init(this.$refs.echarts2)
      const option = {
        title: {
          text: '申请投资利率',
          textStyle: {
            color: '#162747',
            fontFamily: "微软雅黑",
            fontWeight: 'normal',
            fontSize: pieFontSize1
          }
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            interval: 0,
            rotate: 60,
            margin: marginHeight,
            textStyle: {
              color: '#92A2BC',
              fontSize: 12
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#92A2BC'
            }
          },
          axisTick: {
            show: false
          },
        },
        yAxis: {
          // type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#92A2BC'
            }
          },
          axisLabel: {
            show: true,
            interval: 'auto',
            fontFamily: 'Microsoft YaHei',
            fontSize: pieFontSize,
            formatter: '{value} %'
          },
          axisTick: {
            show: true,
            length: 3
          },
        },
        grid: {
          left: '10%',
          right: '2%',
          top: '18%',
          bottom: '21%',
        },
        tooltip: {
          borderWidth: 0,
          textStyle: {
            color: '#162747',
          },
          formatter: function (params) {
            if (params.value > 1) {
              return params['value'] + '%'
            } else {
              return params['value'] + '%'
            }
          }
        },
        series: [
          {
            type: 'bar',
            center: ['50%', '50%'],
            radius: [0, '70%'],
            barWidth: '12',
            itemStyle: {
              normal: {
                color: '#339C9B',
                barBorderRadius: [100, 100, 0, 0]
              },
            },
            data: this.DateList2
          }
        ]
      }
      this.echartsbar(this.DateList2, '申请投资利率', '', pieFontSize, pieFontSize1, yLineHeight, marginHeight, gridbottom)
    },
    async echarts3(pieFontSize, pieFontSize1, yLineHeight, marginHeight, gridbottom) {
      let res = await pubSeaApplyInvestTerm()
      if (res.data.length == 0) {
        this.echarts3Flag = false
      }
      console.log(res)
      res.data.forEach((item, index) => {
        this.DateList3[index] = []
        this.DateList3[index][0] = item.name
        this.DateList3[index][1] = item.term
      })
      this.fourChart = this.$echarts.init(this.$refs.echarts3)
      this.echartsbar(this.DateList3, '申请投资账期', '天', pieFontSize, pieFontSize1, yLineHeight, marginHeight, gridbottom)
    },
    async echarts4(pieFontSize, pieFontSize1, yLineHeightT) {
      let res = await pubSeaApplyTrend()
      if (res.data.length == 0) {
        this.echarts4Flag = false
      }
      console.log(res)
      this.DateList4[0] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      this.DateList4[1] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      res.data.forEach((item, index) => {
        this.DateList4[0][item.date - 1] = item.amount
        this.DateList4[1][item.date - 1] = item.num
      })
      this.fourChart = this.$echarts.init(this.$refs.echarts4)
      const option = {
        title: {
          text: '申请趋势',
          textStyle: {
            color: '#162747',
            fontFamily: "微软雅黑",
            fontWeight: 'normal',
            fontSize: pieFontSize1
          }
        },
        legend: {
          show: true,
          orient: 'horizontal',
          right: '2%',
          itemGap: 20,
          itemWidth: 15,
          itemHeight: 15,
          icon: 'roundRect',
          data: ['申请投资额', '申请投资单数']
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            margin: 8,
            textStyle: {
              color: '#92A2BC',
              fontSize: pieFontSize
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#92A2BC'
            }
          },
          axisTick: {
            show: false
          },
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
        },
        yAxis: [{
          type: 'value',
          position: 'right',
          axisLabel: {
            show: true,
            fontFamily: 'Microsoft YaHei',
            fontSize: pieFontSize,
            formatter: function (value) {
              if (value.toString().length < 6) {
                return value.toString()
              } else {
                return value.toString().slice(0, 5) + '...'
              }
            }
          },
          splitLine: false,
          axisTick: {
            show: false
          },
          name: '元',
          nameTextStyle: {
            lineHeight: yLineHeightT
          },
        },
        {
          type: 'value',
          position: 'left',
          axisLabel: {
            show: true,
            fontFamily: 'Microsoft YaHei',
            fontSize: pieFontSize,
            formatter: '{value}'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#92A2BC'
            }
          },
          axisTick: {
            show: true,
            length: 3
          },
          name: '单数',
          nameTextStyle: {
            lineHeight: yLineHeightT
          },
        }
        ],
        grid: {
          left: '9%',
          right: '10%',
          top: '23%',
          bottom: '9%',
        },
        tooltip: {
          trigger: 'axis',
          textStyle: {
            color: '#162747',
          },
          axisPointer: {
            type: 'cross'
          }
        },
        series: [
          {
            name: '申请投资额',
            type: 'line',
            smooth: true,
            center: ['50%', '50%'],
            radius: [0, '70%'],
            barWidth: '12',
            itemStyle: {
              normal: {
                color: '#FF9B15',
                barBorderRadius: [100, 100, 0, 0]
              },
            },
            data: this.DateList4[0]
          },
          {
            name: '申请投资单数',
            yAxisIndex: 1,
            type: 'bar',
            center: ['50%', '50%'],
            radius: [0, '70%'],
            barWidth: '12',
            itemStyle: {
              normal: {
                color: '#339C9B',
                barBorderRadius: [100, 100, 0, 0]
              },
            },
            data: this.DateList4[1]
          }
        ]
      }
      this.fourChart.setOption(option)
      // this.fourChart.resize()
    },
    echarts5(pieFontSize, pieFontSize1) {
      if (this.DateList5[0].value == 0 && this.DateList5[1].value == 0) {
        this.echarts5Flag = false
      }
      this.fourChart = this.$echarts.init(this.$refs.echarts5)
      const option = {
        tooltip: {
          borderWidth: 0,
          textStyle: {
            color: '#162747',
          },
          formatter: function (params) {
            if (params.value > 1) {
              return params['name'] + '服务数' + params['value'] + '个'
            } else {
              return params['name'] + '服务数' + params['value'] + '个'
            }
          }
        },
        legend: {
          orient: 'horizontal',
          left: '0%',
          itemGap: 20,
          itemWidth: 15,
          itemHeight: 15
        },
        series: [
          {
            z: '2',
            type: 'pie',
            clockwise: false,
            startAngle: 72,
            center: ['50%', '55%'],
            radius: [0, '70%'],
            emphasis: {
              itemStyle: {
                shadowBlur: 5,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              show: true,
              fontSize: pieFontSize1,
              position: 'inside',
              borderWidth: 0,
              color: '#fff',
              formatter: function (params) { return (params.percent.toFixed(0) + '%') }
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 30
            },
            itemStyle: {
              color: function (params) {
                const colorList = [
                  '#FF9B15',
                  '#339C9B'
                ]
                return colorList[params.dataIndex]
              },
            },
            data: this.DateList5
          },
          {
            z: '1',
            type: 'pie',
            clockwise: false,
            startAngle: 72,
            center: ['50%', '55%'],
            radius: [0, '70%'],
            label: {
              show: true,
              fontSize: pieFontSize,
              borderWidth: 0,
              color: 'inherit',
              formatter: function (params) { return (params['name']) }
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 30
            },
            itemStyle: {
              show: false,
              color: function (params) {
                const colorList = [
                  '#FF9B15',
                  '#339C9B'
                ]
                return colorList[params.dataIndex]
              },
            },
            data: this.DateList5
          }
        ]
      }
      this.fourChart.setOption(option)
      // this.fourChart.resize()
    },
    echarts6(pieFontSize, pieFontSize1) {
      if (this.DateList6[0].value == 0 && this.DateList6[1].value == 0) {
        this.echarts6Flag = false
      }
      this.fourChart = this.$echarts.init(this.$refs.echarts6)
      const option = {
        tooltip: {
          borderWidth: 0,
          textStyle: {
            color: '#162747',
          },
          formatter: function (params) {
            if (params.value > 1) {
              return params['name'] + '单数：' + params['value'] + '个'
            } else {
              return params['name'] + '单数：' + params['value'] + '个'
            }
          }
        },
        legend: {
          orient: 'horizontal',
          left: '0%',
          itemGap: 20,
          itemWidth: 15,
          itemHeight: 15
        },
        series: [
          {
            z: '2',
            type: 'pie',
            clockwise: false,
            startAngle: 72,
            center: ['50%', '55%'],
            radius: [0, '70%'],
            emphasis: {
              itemStyle: {
                shadowBlur: 5,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              show: true,
              fontSize: pieFontSize1,
              position: 'inside',
              borderWidth: 0,
              color: '#fff',
              formatter: function (params) { return (params.percent.toFixed(0) + '%') }
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 30
            },
            itemStyle: {
              color: function (params) {
                const colorList = [
                  '#FF9B15',
                  '#339C9B'
                ]
                return colorList[params.dataIndex]
              },
            },
            data: this.DateList6
          },
          {
            z: '1',
            type: 'pie',
            clockwise: false,
            startAngle: 72,
            center: ['50%', '55%'],
            radius: [0, '70%'],
            label: {
              show: true,
              fontSize: pieFontSize,
              borderWidth: 0,
              color: 'inherit',
              formatter: function (params) { return (params['name']) }
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 30
            },
            itemStyle: {
              color: function (params) {
                const colorList = [
                  '#FF9B15',
                  '#339C9B'
                ]
                return colorList[params.dataIndex]
              },
            },
            data: this.DateList6
          }
        ]
      }
      this.fourChart.setOption(option)
      // this.fourChart.resize()
    },
    echarts7(pieFontSize, pieFontSize1) {
      if (this.DateList7[0].value == 0 && this.DateList7[1].value == 0) {
        this.echarts7Flag = false
      }
      this.fourChart = this.$echarts.init(this.$refs.echarts7)
      const option = {
        tooltip: {
          borderWidth: 0,
          textStyle: {
            color: '#162747',
          },
          formatter: function (params) {
            if (params.value > 1) {
              return params['name'] + '：' + params['value'] + '元'
            } else {
              return params['name'] + '：' + params['value'] + '元'
            }
          }
        },
        legend: {
          orient: 'horizontal',
          left: '0%',
          itemGap: 20,
          itemWidth: 15,
          itemHeight: 15
        },
        series: [
          {
            z: '2',
            type: 'pie',
            clockwise: false,
            startAngle: 72,
            center: ['50%', '55%'],
            radius: [0, '70%'],
            emphasis: {
              itemStyle: {
                shadowBlur: 5,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              show: true,
              fontSize: pieFontSize1,
              position: 'inside',
              borderWidth: 0,
              color: '#fff',
              formatter: function (params) { return (params.percent.toFixed(0) + '%') }
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 30
            },
            itemStyle: {
              color: function (params) {
                const colorList = [
                  '#FF9B15',
                  '#339C9B'
                ]
                return colorList[params.dataIndex]
              },
            },
            data: this.DateList7
          },
          {
            z: '1',
            type: 'pie',
            clockwise: false,
            startAngle: 72,
            center: ['50%', '55%'],
            radius: [0, '70%'],
            label: {
              show: true,
              fontSize: pieFontSize,
              borderWidth: 0,
              color: 'inherit',
              formatter: function (params) { return (params['name']) }
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 30
            },
            itemStyle: {
              color: function (params) {
                const colorList = [
                  '#FF9B15',
                  '#339C9B'
                ]
                return colorList[params.dataIndex]
              },
            },
            data: this.DateList7
          }
        ]
      }
      this.fourChart.setOption(option)
      // this.fourChart.resize()
    },
    async echarts8(pieFontSize, pieFontSize1, yLineHeight, marginHeight, gridbottom) {
      let res = await riskPoolOverdueDistribution()
      console.log(res)
      if (res.count == null) {
        this.echarts8Flag = false
      }
      this.maxData[0] = '0' + '-' + res.data.t.t
      for (let i = 1; i < 10; i++) {
        this.maxData[i] = (res.data.t.t * i + 1) + '-' + res.data.t.t * (i + 1)
      }
      res.data.list.forEach((item, index) => {
        this.DateList8[index] = []
        this.DateList8[index] = item.num
      })
      this.fourChart = this.$echarts.init(this.$refs.echarts8)
      const option = {
        title: {
          text: '逾期分布',
          textStyle: {
            color: '#162747',
            fontFamily: "微软雅黑",
            fontWeight: 'normal',
            fontSize: pieFontSize1
          }
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            interval: 0,
            rotate: 60,
            margin: marginHeight,
            textStyle: {
              color: '#92A2BC',
              fontSize: 12
            },
            // formatter:function(value){
            //     if ((value.length > 5)&& (tit != '逾期分布')) {
            //         return value.slice(0,5) + '..'
            //     } else {
            //         return value
            //     }
            // }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#92A2BC'
            }
          },
          axisTick: {
            show: false
          },
          data: this.maxData
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            show: true,
            interval: 'auto',
            fontFamily: 'Microsoft YaHei',
            fontSize: pieFontSize,
            formatter: function (value) {
              return value
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#92A2BC'
            }
          },
          axisTick: {
            show: true,
            length: 3
          },
          name: '单数',
          nameTextStyle: {
            lineHeight: yLineHeight
          },
        },
        grid: {
          left: '10%',
          right: '2%',
          top: '18%',
          bottom: gridbottom,
        },
        tooltip: {
          borderWidth: 0,
          textStyle: {
            color: '#162747',
          },
          formatter: function (params) {
            return params['value'] + '单'
          }
        },
        series: [
          {
            type: 'bar',
            center: ['50%', '50%'],
            radius: [0, '70%'],
            barWidth: '12',
            itemStyle: {
              normal: {
                color: '#339C9B',
                barBorderRadius: [100, 100, 0, 0]
              },
            },
            data: this.DateList8
          }
        ]
      }
      this.fourChart.setOption(option)
      this.fourChart.resize()
    },
    async echarts9(pieFontSize, pieFontSize1, yLineHeight, marginHeight, gridbottom, fontWidthbar, offset) {
      let res = await riskPoolOverdueInvest()
      if (res.data.length == 0) {
        this.echarts9Flag = false
      }
      res.data.forEach((item, index) => {
        this.DateList9[index] = []
        this.DateList9[index][0] = item.name
        this.DateList9[index][1] = item.amount
      })
      this.fourChart = this.$echarts.init(this.$refs.echarts9)
      const option = {
        title: {
          text: '十大逾期投资',
          textStyle: {
            color: '#162747',
            fontFamily: "微软雅黑",
            fontWeight: 'normal',
            fontSize: pieFontSize1
          }
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            interval: 0,
            rotate: 60,
            margin: marginHeight,
            textStyle: {
              color: '#92A2BC',
              fontSize: 12
            },
            formatter: function (value) {
              return value.slice(0, 4) + '..'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#92A2BC'
            }
          },
          axisTick: {
            show: false
          },
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            show: true,
            interval: 'auto',
            fontFamily: 'Microsoft YaHei',
            fontSize: pieFontSize,
            formatter: '{value}'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#92A2BC'
            }
          },
          axisTick: {
            show: true,
            length: 3
          },
          name: '元',
          nameTextStyle: {
            lineHeight: yLineHeight
          },
        },
        grid: {
          left: '10%',
          right: '2%',
          top: '18%',
          bottom: gridbottom,
        },
        tooltip: {
          borderWidth: 0,
          textStyle: {
            color: '#162747',
          },
          formatter: function (params) {
            if (params.value > 1) {
              return params['value'] + '元'
            } else {
              return params['value'] + '元'
            }
          }
        },
        series: [
          {
            // name: this.DateList9X,
            type: 'bar',
            center: ['50%', '50%'],
            radius: [0, '70%'],
            barWidth: fontWidthbar,
            itemStyle: {
              normal: {
                color: '#339C9B',
                barBorderRadius: [100, 100, 0, 0]
              },
            },
            label: {
              show: true,
              rotate: 90,
              offset: offset,
              verticalAlign: 'center',
              position: 'insideBottomLeft',
              textStyle: {
                color: "#162747",
                fontFamily: "微软雅黑",
                fontWeight: "normal",
                fontSize: pieFontSize,
                lineHeight: fontWidthbar
              },
              formatter: function (params) {
                return params['name']
                // return params['name'].split("").join("\n")
              }
            },
            data: this.DateList9
          }
        ]
      }
      this.fourChart.setOption(option)
      // this.fourChart.resize()
    },
    echarts10(pieFontSize, pieFontSize1) {
      if (this.DateList10[0].value == 0 && this.DateList10[1].value == 0) {
        this.echarts10Flag = false
      }
      this.fourChart = this.$echarts.init(this.$refs.echarts10)
      const option = {
        tooltip: {
          borderWidth: 0,
          textStyle: {
            color: '#162747',
          },
          formatter: function (params) {
            if (params.value > 1) {
              return params['name'] + '：' + params['value'] + '个'
            } else {
              return params['name'] + '：' + params['value'] + '个'
            }
          }
        },
        legend: {
          orient: 'horizontal',
          left: '0%',
          itemGap: 20,
          itemWidth: 15,
          itemHeight: 15
        },
        series: [
          {
            z: '2',
            type: 'pie',
            clockwise: false,
            startAngle: 72,
            center: ['50%', '55%'],
            radius: [0, '70%'],
            emphasis: {
              itemStyle: {
                shadowBlur: 5,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              show: true,
              fontSize: pieFontSize1,
              position: 'inside',
              borderWidth: 0,
              color: '#fff',
              formatter: function (params) { return (params.percent.toFixed(0) + '%') }
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 30
            },
            itemStyle: {
              color: function (params) {
                const colorList = [
                  '#FF9B15',
                  '#339C9B'
                ]
                return colorList[params.dataIndex]
              },
            },
            data: this.DateList10
          },
          {
            z: '1',
            type: 'pie',
            clockwise: false,
            startAngle: 72,
            center: ['50%', '55%'],
            radius: [0, '70%'],
            label: {
              show: true,
              fontSize: pieFontSize,
              borderWidth: 0,
              color: 'inherit',
              formatter: function (params) { return (params['name']) }
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 30
            },
            itemStyle: {
              color: function (params) {
                const colorList = [
                  '#FF9B15',
                  '#339C9B'
                ]
                return colorList[params.dataIndex]
              },
            },
            data: this.DateList10
          }
        ]
      }
      this.fourChart.setOption(option)
      // this.fourChart.resize()
    },
    echarts11(pieFontSize, pieFontSize1) {
      if (this.DateList11[0].value == 0 && this.DateList11[1].value == 0) {
        this.echarts11Flag = false
      }
      this.fourChart = this.$echarts.init(this.$refs.echarts11)
      const option = {
        tooltip: {
          borderWidth: 0,
          textStyle: {
            color: '#162747',
          },
          formatter: function (params) {
            if (params.value > 1) {
              return params['name'] + '：' + params['value'] + '元'
            } else {
              return params['name'] + '：' + params['value'] + '元'
            }
          }
        },
        legend: {
          orient: 'horizontal',
          left: '0%',
          itemGap: 20,
          itemWidth: 15,
          itemHeight: 15
        },
        series: [
          {
            z: '2',
            type: 'pie',
            clockwise: false,
            startAngle: 72,
            center: ['50%', '55%'],
            radius: [0, '70%'],
            emphasis: {
              itemStyle: {
                shadowBlur: 5,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              show: true,
              fontSize: pieFontSize1,
              position: 'inside',
              borderWidth: 0,
              color: '#fff',
              formatter: function (params) { return (params.percent.toFixed(0) + '%') }
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 30
            },
            itemStyle: {
              color: function (params) {
                const colorList = [
                  '#FF9B15',
                  '#339C9B'
                ]
                return colorList[params.dataIndex]
              },
            },
            data: this.DateList11
          },
          {
            z: '1',
            type: 'pie',
            clockwise: false,
            startAngle: 72,
            center: ['50%', '55%'],
            radius: [0, '70%'],
            label: {
              show: true,
              fontSize: pieFontSize,
              borderWidth: 0,
              color: 'inherit',
              formatter: function (params) { return (params['name']) }
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 30
            },
            itemStyle: {
              color: function (params) {
                const colorList = [
                  '#FF9B15',
                  '#339C9B'
                ]
                return colorList[params.dataIndex]
              },
            },
            data: this.DateList11
          }
        ]
      }
      this.fourChart.setOption(option)
      // this.fourChart.resize()
    },
    async echarts12(pieFontSize, pieFontSize1, yLineHeight, marginHeight, gridbottom) {
      let res = await investAmount()
      if (res.data.length == 0) {
        this.echarts12Flag = false
      }
      res.data.forEach((item, index) => {
        this.DateList12[index] = []
        this.DateList12[index][0] = item.name
        this.DateList12[index][1] = item.amount
      })
      this.fourChart = this.$echarts.init(this.$refs.echarts12)
      this.echartsbar(this.DateList12, '我的投资金额', '元', pieFontSize, pieFontSize1, yLineHeight, marginHeight, gridbottom)
    },
    async echarts13(pieFontSize, pieFontSize1, yLineHeight, marginHeight, gridbottom) {
      let res = await investRate()
      if (res.data.length == 0) {
        this.echarts13Flag = false
      }
      res.data.forEach((item, index) => {
        this.DateList13[index] = []
        this.DateList13[index][0] = item.name
        this.DateList13[index][1] = item.rate
      })
      this.fourChart = this.$echarts.init(this.$refs.echarts13)
      this.echartsbar(this.DateList13, '我的投资利率', '', pieFontSize, pieFontSize1, yLineHeight, marginHeight, gridbottom)
    },
    async echarts14(pieFontSize, pieFontSize1, yLineHeight, marginHeight, gridbottom) {
      let res = await investCollectionDays()
      if (res.data.length == 0) {
        this.echarts14Flag = false
      }
      res.data.forEach((item, index) => {
        this.DateList14[index] = []
        this.DateList14[index][0] = item.name
        this.DateList14[index][1] = item.days
      })
      this.fourChart = this.$echarts.init(this.$refs.echarts14)
      this.echartsbar(this.DateList14, '我的回款天数', '天数', pieFontSize, pieFontSize1, yLineHeight, marginHeight, gridbottom)
    },
    async echarts15(pieFontSize, pieFontSize1, yLineHeight) {
      let res = await investTrend()
      if (res.data.length == 0) {
        this.echarts15Flag = false
      }
      this.DateList15[0] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      this.DateList15[1] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      res.data.forEach((item, index) => {
        console.log(item.date - 1)
        this.DateList15[0][item.date - 1] = item.num
        this.DateList15[1][item.date - 1] = item.amount
      })
      console.log(this.DateList15)
      this.fourChart = this.$echarts.init(this.$refs.echarts15)
      const option = {
        title: {
          text: '投资趋势',
          textStyle: {
            color: '#162747',
            fontFamily: "微软雅黑",
            fontWeight: 'normal',
            fontSize: pieFontSize1
          }
        },
        legend: {
          show: true,
          orient: 'horizontal',
          right: '2%',
          itemGap: 20,
          itemWidth: 15,
          itemHeight: 15,
          icon: 'roundRect',
          data: ['投资单数', '投资额']
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            margin: 8,
            textStyle: {
              color: '#92A2BC',
              fontSize: pieFontSize
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#92A2BC'
            }
          },
          axisTick: {
            show: false
          },
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
        },
        yAxis: [{
          type: 'value',
          position: 'right',
          axisLabel: {
            show: true,
            fontFamily: 'Microsoft YaHei',
            fontSize: pieFontSize,
            formatter: function (value) {
              if (value.toString().length < 6) {
                return value.toString()
              } else {
                return value.toString().slice(0, 5) + '...'
              }
            }
          },
          splitLine: false,
          axisTick: {
            show: false
          },
          name: '元',
          nameTextStyle: {
            lineHeight: yLineHeight
          },
        },
        {
          type: 'value',
          position: 'left',
          axisLabel: {
            show: true,
            fontFamily: 'Microsoft YaHei',
            fontSize: pieFontSize,
            formatter: '{value}'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#92A2BC'
            }
          },
          axisTick: {
            show: true,
            length: 3
          },
          name: '单数',
          nameTextStyle: {
            lineHeight: yLineHeight
          },
        }
        ],
        grid: {
          left: '10%',
          right: '10%',
          top: '23%',
          bottom: '9%',
        },
        tooltip: {
          trigger: 'axis',
          textStyle: {
            color: '#162747',
          },
          axisPointer: {
            type: 'cross'
          }
        },
        series: [
          {
            name: '投资单数',
            type: 'line',
            smooth: true,
            center: ['50%', '50%'],
            radius: [0, '70%'],
            barWidth: '12',
            itemStyle: {
              normal: {
                color: '#FF9B15',
                barBorderRadius: [100, 100, 0, 0]
              },
            },
            data: this.DateList15[0]
          },
          {
            name: '投资额',
            yAxisIndex: 1,
            type: 'bar',
            center: ['50%', '50%'],
            radius: [0, '70%'],
            barWidth: '12',
            itemStyle: {
              normal: {
                color: '#339C9B',
                barBorderRadius: [100, 100, 0, 0]
              },
            },
            data: this.DateList15[1]
          }
        ]
      }
      this.fourChart.setOption(option)
      // this.fourChart.resize()
    },
    echarts16(pieFontSize, pieFontSize1) {
      if (this.DateList16[0].value == 0 && this.DateList16[1].value == 0) {
        this.echarts16Flag = false
      }
      this.fourChart = this.$echarts.init(this.$refs.echarts16)
      const option = {
        tooltip: {
          borderWidth: 0,
          textStyle: {
            color: '#162747',
          },
          formatter: function (params) {
            if (params.value > 1) {
              return params['name'] + '服务数' + params['value'] + '个'
            } else {
              return params['name'] + '服务数' + params['value'] + '个'
            }
          }
        },
        legend: {
          orient: 'horizontal',
          left: '0%',
          itemGap: 20,
          itemWidth: 15,
          itemHeight: 15
        },
        series: [
          {
            z: '2',
            type: 'pie',
            clockwise: false,
            startAngle: 72,
            center: ['50%', '55%'],
            radius: [0, '70%'],
            emphasis: {
              itemStyle: {
                shadowBlur: 5,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              show: true,
              fontSize: pieFontSize1,
              position: 'inside',
              borderWidth: 0,
              color: '#fff',
              formatter: function (params) { return (params.percent.toFixed(0) + '%') }
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 30
            },
            itemStyle: {
              color: function (params) {
                const colorList = [
                  '#FF9B15',
                  '#339C9B'
                ]
                return colorList[params.dataIndex]
              },
            },
            data: this.DateList16
          },
          {
            z: '1',
            type: 'pie',
            clockwise: false,
            startAngle: 72,
            center: ['50%', '55%'],
            radius: [0, '70%'],
            label: {
              show: true,
              fontSize: pieFontSize,
              borderWidth: 0,
              color: 'inherit',
              formatter: function (params) { return (params['name']) }
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 30
            },
            itemStyle: {
              show: false,
              color: function (params) {
                const colorList = [
                  '#FF9B15',
                  '#339C9B'
                ]
                return colorList[params.dataIndex]
              },
            },
            data: this.DateList16
          }
        ]
      }
      this.fourChart.setOption(option)
      // this.fourChart.resize()
    },
    resizeFn() {
      if (document.body.clientWidth > 1664) {
        this.pieFontSizeTit = 12
        this.yLineHeight = 18
        this.yLineHeightT = 18
        this.marginHeight = 8
        this.fontWidthbar = 20
        this.offset = [0, 6]
      } else {
        this.pieFontSizeTit = 12
        this.yLineHeight = -11
        this.yLineHeightT = 0
        this.marginHeight = 3
        this.fontWidthbar = 20
        this.offset = [0, 6]
      }
    },
  }
}
</script>
<style scoped>
  .nodata {
    width: 220px;
    height: 20px;
    margin: 0 auto;
    line-height: 20px;
    font-size: 16px;
    font-family: '微软雅黑';
    color: #92a2bc;
  }
  .nodataTop {
    margin-top: 150px;
  }
  .text_overflow {
    width: 85px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: right;
    cursor: pointer;
  }
  .deg180 {
    transform: rotate(180deg);
  }
  /* .workbench_lunbo {
                                                                                                                                                                                                                                              width: 100%;
                                                                                                                                                                                                                                              padding-top: 10px;
                                                                                                                                                                                                                                              padding-bottom: 40px;
                                                                                                                                                                                                                                          } */
  /* .workbench_lunbo_prev {
                                                                                                                                                                                                                                              width: 50px;
                                                                                                                                                                                                                                              height: 50px;
                                                                                                                                                                                                                                              margin-right: 10px;
                                                                                                                                                                                                                                              border-radius: 50%;
                                                                                                                                                                                                                                          } */
  /* .workbench_lunbo_next {
                                                                                                                                                                                                                                              width: 50px;
                                                                                                                                                                                                                                              height: 50px;
                                                                                                                                                                                                                                              margin-left: 10px;
                                                                                                                                                                                                                                              border-radius: 50%;
                                                                                                                                                                                                                                          } */
  .blue {
    box-shadow: 0px 8px 14px 0px rgba(47, 160, 159, 0.23);
  }
  .white {
    box-shadow: 0px 8px 14px 0px rgba(96, 135, 135, 0.23);
  }
  .investor_workbench_table_title_total {
    padding: 20px 0;
    background: #edf2f5;
    box-shadow: 0px 0px 5px 0px rgba(9, 44, 55, 0.28) inset;
    border-radius: 10px;
    margin-bottom: 40px;
  }
  /* .workbench_link_div li {
                                                                                                                                                                                                                                              margin-right: 36px;
                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                          .workbench_link_div li:last-child {
                                                                                                                                                                                                                                              margin-right: 0;
                                                                                                                                                                                                                                          } */
  .backtop_div {
    width: 40px;
    height: 40px;
    background: #ffffff;
    box-shadow: 0px 6px 12px 0px rgba(50, 150, 149, 0.19);
  }
  ::v-deep .el-icon-caret-top:before {
    display: none;
    content: '\e6e0';
  }
  .backtop_div .icon-huidaodingbu {
    font-size: 36px;
    color: #339c9b;
  }
  .workbench_table_title_more .icon-xiayiye-xianxing3-0 {
    padding-left: 6px;
    color: #339c9b;
  }
  .workbench_table_title_total_tit {
    width: 225px;
  }
  .workbench_table_title_total_tit1 {
    width: 340px;
  }
  .table_common_work_echart1_allwidth_tit {
    height: 16px;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .table_common_work_echart_details2 {
    height: 280px !important;
  }
  .table_common_work_echart_details {
    width: 240px;
    height: 236px;
    padding: 10px 20px 0 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px rgba(9, 44, 55, 0.28) inset;
  }
  .table_common_work_echart_details1 {
    width: 320px;
  }
  .table_common_work_echart_details li {
    line-height: 38px;
    font-size: 14px;
  }
  .table_common_work_echart_details .tit {
    color: #92a2bc;
  }
  .table_common_work_echart1 {
    width: 400px;
    height: 100%;
  }
  .table_common_work_echart1_allwidth {
    width: 100%;
    height: 100%;
  }
  .table_common_work_echart11 {
    margin-bottom: 0 !important;
    width: 100% !important;
  }
  .table_common_work_echart7 {
    height: 340px !important;
  }
  .table_common_work_echart2 {
    height: 320px !important;
  }
  .table_common_work_echart {
    width: 700px;
    height: 276px;
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: #edf2f5;
  }
  .table_common_work_tit {
    line-height: 22px;
    font-size: 18px;
    margin-bottom: 20px;
  }
  .workbench_lunbo_con {
    width: 1380px;
    overflow: hidden;
  }
  .workbench_link_div {
    width: auto;
    transform: translate3d(0px, 0px, 0px);
  }

  .workbench_link_div li {
    width: 220px;
    /* height: 328px; */
    margin: 0 28px;
    border-radius: 14px;
    background-color: #fff;
    text-align: center;
    box-shadow: 0px 10px 14px 0px rgba(12, 79, 71, 0.16);
    flex-shrink: 0;
  }

  .workbench_tit {
    line-height: 60px;
    font-size: 16px;
    color: #339c9b;
  }

  .workbench_icon {
    width: 130px;
    height: 140px;
    padding-bottom: 10px;
    margin: 0 auto;
    border-radius: 50%;
  }

  .workbench_tol {
    line-height: 38px;
    font-size: 14px;
    color: #92a2bc;
  }

  .workbench_num {
    height: 30px;
    line-height: 30px;
    margin-bottom: 30px;
    font-family: 'Microsoft YaHei Bold';
    font-size: 30px;
  }

  .workbench_bottom {
    width: 100%;
    height: 30px;
    background-color: #339c9b;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
  }

  .workbench_bottom .icon-xiayiye-xianxing3-0 {
    color: #fff;
    transform: rotate(90deg);
    padding-left: 12px !important;
    font-size: 18px;
  }
  @media screen and (max-width: 1664px) {
    .nodata {
      width: 150px;
      height: 12px;
      margin: 0 auto;
      line-height: 12px;
      font-size: 12px;
      font-family: '微软雅黑';
      color: #92a2bc;
    }
    .nodataTop {
      margin-top: 100px;
    }
    .text_overflow {
      width: 74px;
    }
    .investor_workbench_table_title_total {
      padding: 13px 0;
      background: #edf2f5;
      box-shadow: 0px 0px 4px 0px rgba(9, 44, 55, 0.28) inset;
      border-radius: 7px;
      margin-bottom: 27px;
    }
    .workbench_link_div li {
      margin-right: 5px;
    }
    .workbench_link_div li:last-child {
      margin-right: 0;
    }
    .backtop_div {
      width: 27px;
      height: 27px;
      background: #ffffff;
      box-shadow: 0px 5px 8px 0px rgba(50, 150, 149, 0.19);
    }
    ::v-deep .el-icon-caret-top:before {
      display: none;
      content: '\e6e0';
    }
    .backtop_div .icon-huidaodingbu {
      font-size: 24px;
      color: #339c9b;
    }
    .workbench_lunbo_prev {
      width: 50px;
      height: 33px;
      margin-right: 6px;
      border-radius: 50%;
    }
    .workbench_lunbo_next {
      width: 50px;
      height: 33px;
      margin-left: 6px;
      border-radius: 50%;
    }
    .workbench_table_title_more .icon-xiayiye-xianxing3-0 {
      padding-left: 4px;
      color: #339c9b;
    }
    .workbench_table_title_total_tit {
      width: 150px;
    }
    .workbench_table_title_total_tit1 {
      width: 227px;
    }
    .table_common_work_echart1_allwidth_tit {
      height: 11px;
      font-size: 12px;
      margin-bottom: 13px;
    }
    .table_common_work_echart_details2 {
      height: 187px !important;
    }
    .table_common_work_echart_details {
      width: 180px;
      height: 157px;
      padding: 7px 13px 0 13px;
      border-radius: 7px;
      background-color: #fff;
      box-shadow: 0px 0px 4px 0px rgba(9, 44, 55, 0.28) inset;
    }
    .table_common_work_echart_details1 {
      width: 213px;
    }
    .table_common_work_echart_details li {
      line-height: 25px;
      font-size: 12px;
    }
    .table_common_work_echart_details .tit {
      color: #92a2bc;
    }
    .table_common_work_echart1 {
      width: 250px;
      height: 100%;
    }
    .table_common_work_echart1_allwidth {
      width: 100%;
      height: 100%;
    }
    .table_common_work_echart11 {
      margin-bottom: 0 !important;
      width: 100% !important;
    }
    .table_common_work_echart7 {
      height: 227px !important;
    }
    .table_common_work_echart2 {
      height: 213px !important;
    }
    .table_common_work_echart {
      width: 467px;
      height: 184px;
      padding: 13px;
      margin-bottom: 7px;
      border-radius: 7px;
      background-color: #edf2f5;
    }
    .table_common_work_tit {
      line-height: 15px;
      font-size: 12px;
      margin-bottom: 13px;
    }
    .workbench_link_div {
      width: 100%;
      padding-top: 7px;
      padding-bottom: 33px;
    }

    .workbench_link_div li {
      width: 147px;
      margin: 0 19px;
      border-radius: 9px;
      background-color: #fff;
      text-align: center;
      box-shadow: 0px 7px 9px 0px rgba(12, 79, 71, 0.16);
    }

    .workbench_tit {
      line-height: 40px;
      font-size: 12px;
      color: #339c9b;
    }

    .workbench_icon {
      width: 87px;
      height: 93px;
      padding-bottom: 7px;
      margin: 0 auto;
      border-radius: 50%;
    }

    .workbench_tol {
      line-height: 25px;
      font-size: 12px;
      color: #92a2bc;
    }

    .workbench_num {
      height: 20px;
      line-height: 20px;
      margin-bottom: 20px;
      font-family: 'Microsoft YaHei Bold';
      font-size: 20px;
    }

    .workbench_bottom {
      width: 100%;
      height: 20px;
      background-color: #339c9b;
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px;
    }

    .workbench_bottom .icon-xiayiye-xianxing3-0 {
      color: #fff;
      transform: rotate(90deg);
      padding-left: 8px !important;
      font-size: 12px;
    }
  }
</style>